import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import ds from './svg/discord.svg';
import gh from './svg/gh.svg';
import steam from './svg/steam.svg';
import tg from './svg/tg.svg';
import yt from './svg/yt.svg';
import track from './music/difoon.mp3';
import '@fortawesome/fontawesome-free/css/all.min.css'; 

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const openLink = (url) => {
    window.open(url, '_blank');
  };


  
  return (
    <>
      <div className="App">
        {loading && (
          <div className="spinner-container">
            <div className="spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        {!loading && (
          <>
            <div className="container">
              <div className="profile">
                <img
                  src="https://cdn.discordapp.com/assets/profile_effects/effects/2024-04-04/all-nighter/idle-frame.png"
                  draggable="false"
                  className="discordGenii"
                />
                <img
                  src="https://i.pinimg.com/originals/ab/6e/dc/ab6edc53577c4fdc39a9ba4151ab9d41.gif"
                  className="banner"
                  alt="banner"
                />
                <div className="profile-header">
                  <div draggable="false" className="avatar-container">
                    <img
                      src="https://cdn.discordapp.com/avatars/1216074950025150644/d6c7e547668244039bd2d8e6d9827fe5.webp?size=128"
                      className="avatar"
                      alt="Profile Avatar"
                    />
                    <span className="status-indicator"></span>
                  </div>
                </div>
                <div className="info">
                  <p className="name">DIFOON</p>
                  <p className="username">difoon • he/him</p>
                  <div className="icons">
                    <img className="icon" src="https://exta.dev/assets/discord/icon2.png" />
                    <img className="icon" src="https://exta.dev/assets/discord/icon6.png" />
                    <img className="icon" src="https://exta.dev/assets/discord/icon5.png" />
                    <img className="icon" src="https://cdn.discordapp.com/badge-icons/991c9f39ee33d7537d9f408c3e53141e.png" />
                    <img className="icon" src="https://exta.dev/assets/discord/icon3.png" />
                    <img className="icon" src="https://exta.dev/assets/discord/icon7.png" />
                    <img className="icon" src="https://exta.dev/assets/discord/icon1.png" />
                  </div>
                </div>
                <div className="line"></div>
                <div className="description">
                  <p className="about-me">ОБО МНЕ:</p>
                  <p className="description-text">Активный разработчик, любитель жизни</p>
                </div>
                <div className="registration">
                  <p className="create">В ЧИСЛЕ УЧАСТНИКОВ DISCORD C</p>
                  <p className="registration-text">9 март. 2024 г.</p>
                </div>
              </div>
            </div>
            <div className="social">
              <button className="social-button" onClick={() => openLink('https://t.me/difoonlive')}>
                <img src={tg} alt="Telegram" />
              </button>
              <button className="social-button" onClick={() => openLink('https://github.com/Difooon')}>
                <img src={gh} alt="GitHub" />
              </button>
              <button
                className="social-button"
                onClick={() => alert('Упс. Кнопка временно недоступна!')}
              >
                <img src={ds} alt="Discord" />
              </button>
              <button className="social-button" onClick={() => openLink('https://steamcommunity.com/id/difoon/')}>
                <img src={steam} alt="Steam" />
              </button>
              <button className="social-button" onClick={() => openLink('https://www.youtube.com/@alsodifoon')}>
                <img src={yt} alt="YouTube" />
              </button>
            </div>
              <div className="copyright">© 2024 DIFOON</div>
          </>
        )}
      </div>
    </>
  );
}
  
export default App;
