import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


//! Wait
window.onload = function() {
  const waitElement = document.querySelector('.wait');
  waitElement.style.display = 'flex'; 

  setTimeout(() => {
      waitElement.style.transition = 'opacity 1s ease';
      waitElement.style.opacity = '0'; 
  }, 2000);

  setTimeout(() => {
      waitElement.style.display = 'none'; 
  }, 3000); 
};

//!! Title
let docTitle = document.title;
window.addEventListener("blur", () => {
    document.title = "DIFOON | Вернись, собака :("; 
})

window.addEventListener("focus", () => {
    document.title = docTitle;
})

reportWebVitals();
